.cuerpo {
  min-height: 100vh;
  /* margin: auto; 
  padding: 20px;*/
  background-color: #fff2a6;
}
.titulo {
  font-weight: 600;
  color: rgb(29, 117, 253);
  font-size: 54px;
  margin: 0;
}
.subtitulo {
  font-size: 28px;
  color: rgb(29, 117, 253);
}
.buttonSubtitle {
  width: 22px;
  height: 22px;
  margin-left: 5px;
  position: absolute;
}
.destacado {
  font-weight: 600;
  color: rgb(29, 117, 253);
  font-size: 16px;
}
.break {
  white-space: normal;
}
.button-play {
  background-color: transparent;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(29, 117, 253);
  border-radius: 1px;
  color: rgb(29, 117, 253);
  font-weight: 600;
  font-size: 10px;
  padding: 6px;
}

.button-on {
  background-color: rgb(29, 117, 253);
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(29, 117, 253);
  border-radius: 5px;
  color: #fff2a6;
  font-weight: 600;
  /*font-size: 12px;*/
  padding: 0px;
  margin: 0px;
  width: 25px;
  height: 25px;
  line-height: 23px;
  font-family: "Alegreya";
}
.button-off {
  background-color: transparent;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(29, 117, 253);
  border-radius: 5px;
  color: rgb(29, 117, 253);
  font-weight: 600;
  /*font-size: 12px;*/
  padding: 0px;
  margin: 0px;
  width: 25px;
  height: 25px;
  line-height: 23px;
  font-family: "Alegreya";
}

table.soundTable {
  text-align: left;
}
table.soundTable td,
table.soundTable th {
  padding: 8px 10px;
  white-space: nowrap;
}
table.soundTable tbody td {
  font-size: 13px;
  font-weight: bold;
}

.estilo-header {
  -webkit-box-align: center !important;

  display: flex !important;
  align-items: center !important;
  /* padding: 1.375rem 0.75rem !important;
    top: 0px !important;
  left: 0px !important;
  right: 0px !important;
  transition: height 600ms ease 0s !important;
  z-index: 100 !important;
  */
  height: 90px !important;
  background-color: rgba(255, 255, 255, 0.9) !important;
  justify-content: space-between;
}

.div-logo-header {
  color: rgb(29, 117, 253) !important;
  cursor: pointer !important;
  margin-left: 20px;
}

.logo-header {
  height: 55px !important;
  transition: all 0.6s ease 0s !important;
}

.img2-header {
  height: 50px !important;
  transform: scaleX(-1) !important;
  transition: all 0.6s ease 0s !important;
}

.div-conteiner-header-img2 {
  margin-right: 20px;
  /*
  -webkit-box-direction: normal !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-align: center !important;
  -webkit-box-pack: end !important;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  flex-direction: row !important;
  width: 100% !important;
  */
}

.centrado {
  /*position: absolute;
  left: 50%;
  transform: translate(-50%);*/
  padding: 20px;
  text-align: center;
  max-width: 1060px;
  margin: auto;
}
.centrado-tabla {
  /* position: absolute; */
  /* left: 50%; */
  /* transform: translate(-50%); */
  /* white-space: nowrap; */
  text-align: justify;
  margin-top: 16px;
  /* padding: 20px; */
}

.list-style {
  text-align: start;
  font-size: 17px;
  margin-bottom: 30px;
  margin-top: 25px;
  line-height: 25px;
  padding-inline-start: 20px;
}

.item-tabla {
  /*padding-right: 15px;*/
  padding-bottom: 5px;
}
.imagesBeat {
  cursor: pointer;
  width: 25px;
  height: 25px;
}
.centrado-boton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.font-sans {
  font-family: "Work Sans";
}

.font-alegreya {
  font-family: "Alegreya";
}

.div-wave {
  position: relative;
  top: 13px;
}

.span-wave {
  display: block;
  bottom: 16px;
  width: 3px;
  height: 1px;
  background: #1d75fd;
  position: absolute;
  animation: audio-wave 1.6s infinite ease-in-out;
}
.span-wave:nth-child(1) {
  left: calc(3px * 1);
  animation-delay: calc(0.2s * 1);
}
.span-wave:nth-child(2) {
  left: calc(3px * 2);
  animation-delay: calc(0.2s * 2);
}
.span-wave:nth-child(3) {
  left: calc(3px * 3);
  animation-delay: calc(0.2s * 3);
}
.span-wave:nth-child(4) {
  left: calc(3px * 4);
  animation-delay: calc(0.2s * 4);
}
.span-wave:nth-child(5) {
  left: calc(3px * 5);
  animation-delay: calc(0.2s * 5);
}
.span-wave:nth-child(6) {
  left: calc(3px * 6);
  animation-delay: calc(0.2s * 6);
}
.span-wave:nth-child(7) {
  left: calc(3px * 7);
  animation-delay: calc(0.2s * 7);
}
.span-wave:nth-child(8) {
  left: calc(3px * 8);
  animation-delay: calc(0.2s * 8);
}
.span-wave:nth-child(9) {
  left: calc(3px * 9);
  animation-delay: calc(0.2s * 9);
}
.span-wave:nth-child(10) {
  left: calc(3px * 10);
  animation-delay: calc(0.2s * 10);
}
.span-wave:nth-child(11) {
  left: calc(3px * 11);
  animation-delay: calc(0.2s * 11);
}
.span-wave:nth-child(12) {
  left: calc(3px * 12);
  animation-delay: calc(0.2s * 12);
}
.span-wave:nth-child(13) {
  left: calc(3px * 13);
  animation-delay: calc(0.2s * 13);
}
.span-wave:nth-child(14) {
  left: calc(3px * 14);
  animation-delay: calc(0.2s * 14);
}
.span-wave:nth-child(15) {
  left: calc(3px * 15);
  animation-delay: calc(0.2s * 15);
}
.span-wave:nth-child(16) {
  left: calc(3px * 16);
  animation-delay: calc(0.2s * 16);
}
.span-wave:nth-child(17) {
  left: calc(3px * 17);
  animation-delay: calc(0.2s * 17);
}
.span-wave:nth-child(18) {
  left: calc(3px * 18);
  animation-delay: calc(0.2s * 18);
}
.span-wave:nth-child(19) {
  left: calc(3px * 19);
  animation-delay: calc(0.2s * 19);
}
.span-wave:nth-child(20) {
  left: calc(3px * 20);
  animation-delay: calc(0.2s * 20);
}
.span-wave:nth-child(21) {
  left: calc(3px * 21);
  animation-delay: calc(0.2s * 21);
}
.span-wave:nth-child(22) {
  left: calc(3px * 22);
  animation-delay: calc(0.2s * 22);
}
.span-wave:nth-child(23) {
  left: calc(3px * 23);
  animation-delay: calc(0.2s * 23);
}
.span-wave:nth-child(24) {
  left: calc(3px * 24);
  animation-delay: calc(0.2s * 24);
}
.span-wave:nth-child(25) {
  left: calc(3px * 25);
  animation-delay: calc(0.2s * 25);
}
.span-wave:nth-child(26) {
  left: calc(3px * 26);
  animation-delay: calc(0.2s * 26);
}
.span-wave:nth-child(27) {
  left: calc(3px * 27);
  animation-delay: calc(0.2s * 27);
}
.span-wave:nth-child(28) {
  left: calc(3px * 28);
  animation-delay: calc(0.2s * 28);
}
.span-wave:nth-child(29) {
  left: calc(3px * 29);
  animation-delay: calc(0.2s * 29);
}
.span-wave:nth-child(30) {
  left: calc(3px * 30);
  animation-delay: calc(0.2s * 30);
}
.span-wave:nth-child(31) {
  left: calc(3px * 31);
  animation-delay: calc(0.2s * 31);
}
.span-wave:nth-child(32) {
  left: calc(3px * 32);
  animation-delay: calc(0.2s * 32);
}
.span-wave:nth-child(33) {
  left: calc(3px * 33);
  animation-delay: calc(0.2s * 33);
}
/*.span-wave:nth-child(34) {
  left: calc(3px * 34);
  animation-delay: calc(0.2s * 34);
}
.span-wave:nth-child(35) {
  left: calc(3px * 35);
  animation-delay: calc(0.2s * 35);
}
.span-wave:nth-child(36) {
  left: calc(3px * 36);
  animation-delay: calc(0.2s * 36);
}
.span-wave:nth-child(37) {
  left: calc(3px * 37);
  animation-delay: calc(0.2s * 37);
}
.span-wave:nth-child(38) {
  left: calc(3px * 38);
  animation-delay: calc(0.2s * 38);
}
.span-wave:nth-child(39) {
  left: calc(3px * 39);
  animation-delay: calc(0.2s * 39);
}
.span-wave:nth-child(40) {
  left: calc(3px * 40);
  animation-delay: calc(0.2s * 40);
}
.span-wave:nth-child(41) {
  left: calc(3px * 41);
  animation-delay: calc(0.2s * 41);
}
.span-wave:nth-child(42) {
  left: calc(3px * 42);
  animation-delay: calc(0.2s * 42);
}
.span-wave:nth-child(43) {
  left: calc(3px * 43);
  animation-delay: calc(0.2s * 43);
}
.span-wave:nth-child(44) {
  left: calc(3px * 44);
  animation-delay: calc(0.2s * 44);
}
.span-wave:nth-child(45) {
  left: calc(3px * 45);
  animation-delay: calc(0.2s * 45);
}
.span-wave:nth-child(46) {
  left: calc(3px * 46);
  animation-delay: calc(0.2s * 46);
}
.span-wave:nth-child(47) {
  left: calc(3px * 47);
  animation-delay: calc(0.2s * 47);
}
.span-wave:nth-child(48) {
  left: calc(3px * 48);
  animation-delay: calc(0.2s * 48);
}
.span-wave:nth-child(49) {
  left: calc(3px * 49);
  animation-delay: calc(0.2s * 49);
}
.span-wave:nth-child(50) {
  left: calc(3px * 50);
  animation-delay: calc(0.2s * 50);
}
.span-wave:nth-child(51) {
  left: calc(3px * 51);
  animation-delay: calc(0.2s * 51);
}
.span-wave:nth-child(52) {
  left: calc(3px * 52);
  animation-delay: calc(0.2s * 52);
}
.span-wave:nth-child(53) {
  left: calc(3px * 53);
  animation-delay: calc(0.2s * 53);
}
.span-wave:nth-child(54) {
  left: calc(3px * 54);
  animation-delay: calc(0.2s * 54);
}
.span-wave:nth-child(55) {
  left: calc(3px * 55);
  animation-delay: calc(0.2s * 55);
}
.span-wave:nth-child(56) {
  left: calc(3px * 56);
  animation-delay: calc(0.2s * 56);
}
.span-wave:nth-child(57) {
  left: calc(3px * 57);
  animation-delay: calc(0.2s * 57);
}
.span-wave:nth-child(58) {
  left: calc(3px * 58);
  animation-delay: calc(0.2s * 58);
}
.span-wave:nth-child(59) {
  left: calc(3px * 59);
  animation-delay: calc(0.2s * 59);
}
.span-wave:nth-child(60) {
  left: calc(3px * 60);
  animation-delay: calc(0.2s * 60);
}
.span-wave:nth-child(61) {
  left: calc(3px * 61);
  animation-delay: calc(0.2s * 61);
}
.span-wave:nth-child(62) {
  left: calc(3px * 62);
  animation-delay: calc(0.2s * 62);
}
.span-wave:nth-child(63) {
  left: calc(3px * 63);
  animation-delay: calc(0.2s * 63);
}
.span-wave:nth-child(64) {
  left: calc(3px * 64);
  animation-delay: calc(0.2s * 64);
}
.span-wave:nth-child(65) {
  left: calc(3px * 65);
  animation-delay: calc(0.2s * 65);
}
.span-wave:nth-child(66) {
  left: calc(3px * 66);
  animation-delay: calc(0.2s * 66);
}
.span-wave:nth-child(67) {
  left: calc(3px * 67);
  animation-delay: calc(0.2s * 67);
}*/
@keyframes audio-wave {
  0% {
    height: 1px;
    transform: translateY(0px);
    background: #9b59b6;
  }
  25% {
    height: 24px;
    transform: translateY(12px);
    background: #3498db;
  }
  /*effect is to animate the height of each span from 5px to 30px*/
  /*translateY makes Y axis move down to give the effect that it is growing from the center*/
  50% {
    height: 1px;
    transform: translateY(0px);
    background: #9b59b6;
  }
  100% {
    height: 1px;
    transform: translateY(0px);
    background: #9b59b6;
  }
}
.span-wave-off {
  display: block;
  bottom: 16px;
  width: 100px;
  height: 1px;
  background: #1d75fd;
  position: absolute;
  animation: audio-wave-off 1.6s infinite ease-in-out;
}
@keyframes audio-wave-off {
  0% {
    height: 1px;
    transform: translateY(0px);
    background: #1d75fd;
  }
}

.ml-3px {
  margin-left: 3px;
}

@media only screen and (min-width: 400px) and (max-width: 600px) {
  .centrado-tabla {
    margin-left: 45px;
  }
  .item-tabla {
    padding-right: 15px;
  }
}

@media only screen and (min-width: 601px) {
  .centrado-tabla {
    margin: auto;
  }
  .destacado {
    font-size: 17px;
  }
  .imagesBeat {
    width: 30px;
    height: 30px;
  }
  .item-tabla {
    padding-right: 15px;
  }
  .div-wave {
    width: 200px;
  }
}

@media only screen and (min-width: 1024px) {
  .button-on,
  .button-off,
  .button-off {
    width: 32px;
    height: 32px;
  }

  .span-wave-off {
    width: 200px;
  }

  .span-wave:nth-child(1) {
    left: calc(3px * 1);
    animation-delay: calc(0.2s * 1);
  }
  .span-wave:nth-child(2) {
    left: calc(3px * 2);
    animation-delay: calc(0.2s * 2);
  }
  .span-wave:nth-child(3) {
    left: calc(3px * 3);
    animation-delay: calc(0.2s * 3);
  }
  .span-wave:nth-child(4) {
    left: calc(3px * 4);
    animation-delay: calc(0.2s * 4);
  }
  .span-wave:nth-child(5) {
    left: calc(3px * 5);
    animation-delay: calc(0.2s * 5);
  }
  .span-wave:nth-child(6) {
    left: calc(3px * 6);
    animation-delay: calc(0.2s * 6);
  }
  .span-wave:nth-child(7) {
    left: calc(3px * 7);
    animation-delay: calc(0.2s * 7);
  }
  .span-wave:nth-child(8) {
    left: calc(3px * 8);
    animation-delay: calc(0.2s * 8);
  }
  .span-wave:nth-child(9) {
    left: calc(3px * 9);
    animation-delay: calc(0.2s * 9);
  }
  .span-wave:nth-child(10) {
    left: calc(3px * 10);
    animation-delay: calc(0.2s * 10);
  }
  .span-wave:nth-child(11) {
    left: calc(3px * 11);
    animation-delay: calc(0.2s * 11);
  }
  .span-wave:nth-child(12) {
    left: calc(3px * 12);
    animation-delay: calc(0.2s * 12);
  }
  .span-wave:nth-child(13) {
    left: calc(3px * 13);
    animation-delay: calc(0.2s * 13);
  }
  .span-wave:nth-child(14) {
    left: calc(3px * 14);
    animation-delay: calc(0.2s * 14);
  }
  .span-wave:nth-child(15) {
    left: calc(3px * 15);
    animation-delay: calc(0.2s * 15);
  }
  .span-wave:nth-child(16) {
    left: calc(3px * 16);
    animation-delay: calc(0.2s * 16);
  }
  .span-wave:nth-child(17) {
    left: calc(3px * 17);
    animation-delay: calc(0.2s * 17);
  }
  .span-wave:nth-child(18) {
    left: calc(3px * 18);
    animation-delay: calc(0.2s * 18);
  }
  .span-wave:nth-child(19) {
    left: calc(3px * 19);
    animation-delay: calc(0.2s * 19);
  }
  .span-wave:nth-child(20) {
    left: calc(3px * 20);
    animation-delay: calc(0.2s * 20);
  }
  .span-wave:nth-child(21) {
    left: calc(3px * 21);
    animation-delay: calc(0.2s * 21);
  }
  .span-wave:nth-child(22) {
    left: calc(3px * 22);
    animation-delay: calc(0.2s * 22);
  }
  .span-wave:nth-child(23) {
    left: calc(3px * 23);
    animation-delay: calc(0.2s * 23);
  }
  .span-wave:nth-child(24) {
    left: calc(3px * 24);
    animation-delay: calc(0.2s * 24);
  }
  .span-wave:nth-child(25) {
    left: calc(3px * 25);
    animation-delay: calc(0.2s * 25);
  }
  .span-wave:nth-child(26) {
    left: calc(3px * 26);
    animation-delay: calc(0.2s * 26);
  }
  .span-wave:nth-child(27) {
    left: calc(3px * 27);
    animation-delay: calc(0.2s * 27);
  }
  .span-wave:nth-child(28) {
    left: calc(3px * 28);
    animation-delay: calc(0.2s * 28);
  }
  .span-wave:nth-child(29) {
    left: calc(3px * 29);
    animation-delay: calc(0.2s * 29);
  }
  .span-wave:nth-child(30) {
    left: calc(3px * 30);
    animation-delay: calc(0.2s * 30);
  }
  .span-wave:nth-child(31) {
    left: calc(3px * 31);
    animation-delay: calc(0.2s * 31);
  }
  .span-wave:nth-child(32) {
    left: calc(3px * 32);
    animation-delay: calc(0.2s * 32);
  }
  .span-wave:nth-child(33) {
    left: calc(3px * 33);
    animation-delay: calc(0.2s * 33);
  }
  .span-wave:nth-child(34) {
    left: calc(3px * 34);
    animation-delay: calc(0.2s * 34);
  }
  .span-wave:nth-child(35) {
    left: calc(3px * 35);
    animation-delay: calc(0.2s * 35);
  }
  .span-wave:nth-child(36) {
    left: calc(3px * 36);
    animation-delay: calc(0.2s * 36);
  }
  .span-wave:nth-child(37) {
    left: calc(3px * 37);
    animation-delay: calc(0.2s * 37);
  }
  .span-wave:nth-child(38) {
    left: calc(3px * 38);
    animation-delay: calc(0.2s * 38);
  }
  .span-wave:nth-child(39) {
    left: calc(3px * 39);
    animation-delay: calc(0.2s * 39);
  }
  .span-wave:nth-child(40) {
    left: calc(3px * 40);
    animation-delay: calc(0.2s * 40);
  }
  .span-wave:nth-child(41) {
    left: calc(3px * 41);
    animation-delay: calc(0.2s * 41);
  }
  .span-wave:nth-child(42) {
    left: calc(3px * 42);
    animation-delay: calc(0.2s * 42);
  }
  .span-wave:nth-child(43) {
    left: calc(3px * 43);
    animation-delay: calc(0.2s * 43);
  }
  .span-wave:nth-child(44) {
    left: calc(3px * 44);
    animation-delay: calc(0.2s * 44);
  }
  .span-wave:nth-child(45) {
    left: calc(3px * 45);
    animation-delay: calc(0.2s * 45);
  }
  .span-wave:nth-child(46) {
    left: calc(3px * 46);
    animation-delay: calc(0.2s * 46);
  }
  .span-wave:nth-child(47) {
    left: calc(3px * 47);
    animation-delay: calc(0.2s * 47);
  }
  .span-wave:nth-child(48) {
    left: calc(3px * 48);
    animation-delay: calc(0.2s * 48);
  }
  .span-wave:nth-child(49) {
    left: calc(3px * 49);
    animation-delay: calc(0.2s * 49);
  }
  .span-wave:nth-child(50) {
    left: calc(3px * 50);
    animation-delay: calc(0.2s * 50);
  }
  .span-wave:nth-child(51) {
    left: calc(3px * 51);
    animation-delay: calc(0.2s * 51);
  }
  .span-wave:nth-child(52) {
    left: calc(3px * 52);
    animation-delay: calc(0.2s * 52);
  }
  .span-wave:nth-child(53) {
    left: calc(3px * 53);
    animation-delay: calc(0.2s * 53);
  }
  .span-wave:nth-child(54) {
    left: calc(3px * 54);
    animation-delay: calc(0.2s * 54);
  }
  .span-wave:nth-child(55) {
    left: calc(3px * 55);
    animation-delay: calc(0.2s * 55);
  }
  .span-wave:nth-child(56) {
    left: calc(3px * 56);
    animation-delay: calc(0.2s * 56);
  }
  .span-wave:nth-child(57) {
    left: calc(3px * 57);
    animation-delay: calc(0.2s * 57);
  }
  .span-wave:nth-child(58) {
    left: calc(3px * 58);
    animation-delay: calc(0.2s * 58);
  }
  .span-wave:nth-child(59) {
    left: calc(3px * 59);
    animation-delay: calc(0.2s * 59);
  }
  .span-wave:nth-child(60) {
    left: calc(3px * 60);
    animation-delay: calc(0.2s * 60);
  }
  .span-wave:nth-child(61) {
    left: calc(3px * 61);
    animation-delay: calc(0.2s * 61);
  }
  .span-wave:nth-child(62) {
    left: calc(3px * 62);
    animation-delay: calc(0.2s * 62);
  }
  .span-wave:nth-child(63) {
    left: calc(3px * 63);
    animation-delay: calc(0.2s * 63);
  }
  .span-wave:nth-child(64) {
    left: calc(3px * 64);
    animation-delay: calc(0.2s * 64);
  }
  .span-wave:nth-child(65) {
    left: calc(3px * 65);
    animation-delay: calc(0.2s * 65);
  }
  .span-wave:nth-child(66) {
    left: calc(3px * 66);
    animation-delay: calc(0.2s * 66);
  }
  .span-wave:nth-child(67) {
    left: calc(3px * 67);
    animation-delay: calc(0.2s * 67);
  }
}
